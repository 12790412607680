import _ from 'lodash'
const braintreeClient = require('braintree-web/client');
const braintreeHostedFields = require('braintree-web/hosted-fields');
let hostedFieldInstance = null;
let braintreeClientInstance = null;

export default (app) => {

    app.ports.braintreeSetup.subscribe(function (token) {
        if (braintreeClientInstance !== null) {
            braintreeClientInstance.teardown()
                .then(() => {
                    initialize()
                })
                .catch(error => {
                    app.ports.creditCardReady.send(false);
                    sendErrorToElm("setupBraintreeClientInstance", error);
                })
        } else {
            initialize()
        }
        // Before next browser repaint
        function initialize() {
            window.requestAnimationFrame(() => {
                setTimeout(() => {
                    setupBraintree(token)
                }, 500)
            })
        }
    });

    // Make subscriptions to Elm's actions
    app.ports.payWithCreditCard.subscribe(() => {
        if (hostedFieldInstance !== null) {
            return hostedFieldInstance.tokenize(tokenized())
        }
    });

    /*
      Braintree setup
      @params [String] braintreeToken - the token
     */
    const setupBraintree = braintreeToken => {
        braintreeClient.create({ authorization: braintreeToken }, braintreeClientReady)
    };

    /*
      Callback of Braintree's setup
      @params [Object] err - the Braintree's error
      @params [Object] clientInstance - the Braintree's instance returned after setup
     */
    const braintreeClientReady = (error, clientInstance) => {
        // Die on setup error
        if (error) {
            sendErrorToElm("braintreeClientReady", error);
            return
        }
        braintreeClientInstance = clientInstance
        // Compose Iframes and style them
        if (hostedFieldInstance !== null) {
            hostedFieldInstance.teardown()
                .then(() => {
                    hostedFieldInstance = null
                    initializeHostedFields()
                })
                .catch(error => {
                    app.ports.creditCardReady.send(false);
                    sendErrorToElm("braintreeClientInstance", error);
                })
        } else {
            initializeHostedFields()
        }
        function initializeHostedFields() {
            braintreeHostedFields.create({
                client: clientInstance,
                styles: {
                    input: 'form-field__text', // class name from Pyxis styles
                },
                fields: {
                    number: {
                        container: '#credit-card-number',
                        placeholder: '4111 1111 1111 1111'
                    },
                    expirationDate: {
                        container: '#credit-card-expiration-date',
                        placeholder: 'MM / YY'
                    }
                }
            }, creditCardSettedUp)
        }
    };
    /*
      Callback of Braintree's DOM render
      @params [Object] err - the Braintree's error
      @params [Object] clientInstance - the Braintree's DOM
     */
    const creditCardSettedUp = (error, braintreeHostedFieldsInstance) => {
        if (error) {
            app.ports.creditCardReady.send(false);
            sendErrorToElm("creditCardSettedUp " + braintreeHostedFieldsInstance, error);
            return;
        }
        // Apply events to Braintree's DOM nodes
        braintreeHostedFieldsInstance.on('validityChange', braintreeValidationResponse)
        braintreeHostedFieldsInstance.on('focus', braintreeValidationResponse)
        braintreeHostedFieldsInstance.on('blur', braintreeValidationResponse)
        hostedFieldInstance = braintreeHostedFieldsInstance

        // Notify Payment app that the DOM can be now manipulated
        app.ports.creditCardReady.send(true)
    }
    /*
      Callback of Braintree's nodes validation
      @params [Object] response - the Braintree's validation response
     */
    const braintreeValidationResponse = (response) => {
        // Transform the Braintree response into an ELM compliant datatype
        // then fire it through the port
        app.ports.validateCreditCardFields.send(mapBraintreeResponse(response))
    };
    /*
      Callback of Braintree's nodes validation
      @params [Object] response - the Braintree's validation response
     */
    const mapBraintreeResponse = response => {
        let fields = response.fields
        let keys = _.keys(fields)

        return _.map(keys, key => {
            let braintreeNode = fields[key]
            let id = braintreeNode.container.id
            let isValid = braintreeNode.isValid
            let isEmpty = braintreeNode.isEmpty
            let isFocused = braintreeNode.isFocused

            // bubble up focus from within iframe to container
            // used for correctly applying ':focus' pseudo-selectors
            if (isFocused) {
                let field = document.querySelector(`label[for="${id}"]`);

                if (field) {
                    field.click();
                }
            }

            return {
                id: id,
                isValid: isValid,
                isPristine: isEmpty,
                isFocused: isFocused
            }
        })
    };
    const tokenized = action => {
        return (tokenizeErr, payload) => {
            // Payment request has failed
            if (tokenizeErr) {
                sendErrorToElm("braintreeTokenizeError", tokenizeErr);
                return
            }

            app.ports.nonceReceived.send(payload.nonce)
        }
    };

    const sendErrorToElm = (context, error) => {
        let errorEvent = {
            context: context,
            error: error.message,
            code: error.code
        };

        if (error && error.details && error.details.originalError) {
            errorEvent.error = error.details.originalError.message;
            if (error.details.originalError.details
                && error.details.originalError.details.originalError
                && error.details.originalError.details.originalError.error) {
                errorEvent.error = error.details.originalError.details.originalError.error.message;
            }
        }
        if (errorEvent.error === undefined) {
            errorEvent.error = "";
        }

        app.ports.notifyErrorEvent.send(errorEvent);
    }
}
