function createStylesFromfeatureFlags(flags) {
    if (flags == null) {
      throw new Error("no flags were passed");
    }

    if (typeof flags != "string") {
      throw new Error("feature flags need to be strings");
    }

    const fragment = new DocumentFragment();

    if (flags.trim() !== "") {
      flags.split(",").forEach((flag) => {
        const style = document.createElement("style");
        const [action, feature] = flag.split("_");
        style.textContent = `.feature-flag--${feature} { display: ${
          action == "hide" ? "none" : "inherit"
        } };`;
        fragment.append(style);
      });
    }

    return fragment;
}

export default createStylesFromfeatureFlags;
