/**
 * @description Custom element to copy text to clipboard
 * @example <copy-button data-text="Copy" data-copy="some-text" data-text-copied="Copied" data-timeout="1500"></copy-button>
 * @param {string} data-text - Text to display on the button
 * @param {string} data-copy - Text to copy to clipboard
 * @param {string} data-text-copied - Text to display on the button after copying
 */
class CopyButton extends HTMLElement {
  constructor() {
    super();
    this.onClickHanlder = this.onClickHanlder.bind(this);
    this.updateCopyText = this.updateCopyText.bind(this);
  }

  connectedCallback() {
    const button = this.buildPyxisButton();

    const copyIconWrapper = this.buildCopyIcon();
    button.appendChild(copyIconWrapper);

    const textElement = this.buildCopyText();
    button.appendChild(textElement);

    this.appendChild(button);

    button.addEventListener("click", this.onClickHanlder);
  }

  buildPyxisButton() {
    const button = document.createElement("button");
    button.classList.add(
      "button",
      "button--ghost",
      "button--m",
      "button--prepend-icon"
    );
    button.setAttribute("type", "button");
    return button;
  }

  buildCopyIcon() {
    const copyIconWrapper = document.createElement("span");
    copyIconWrapper.classList.add("icon", "icon--size-s");
    copyIconWrapper.setAttribute("aria-hidden", "true");
    copyIconWrapper.setAttribute("data-name", "cards-overlap");

    const svgIcon = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    svgIcon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgIcon.setAttribute("viewBox", "0 0 24 24");
    svgIcon.setAttribute("width", "24");
    svgIcon.setAttribute("height", "24");

    const pathIcon = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    pathIcon.setAttribute(
      "d",
      "M18.5 7c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1h-9c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h9m0-2h-9c-1.66 0-3 1.34-3 3v12c0 1.66 1.34 3 3 3h9c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3h0zm-14 9.82V4c0-.55.45-1 1-1H13h1.5 2.82c-.42-1.16-1.52-2-2.82-2h-9c-1.66 0-3 1.34-3 3v12c0 1.3.84 2.4 2 2.82V16v-1.18z"
    );

    svgIcon.appendChild(pathIcon);
    copyIconWrapper.appendChild(svgIcon);
    return copyIconWrapper;
  }

  buildCopyText() {
    const textElement = document.createElement("span");
    const text = this.getAttribute("data-text");
    textElement.classList.add("button__text");
    textElement.textContent = text;
    return textElement;
  }

  onClickHanlder() {
    const text = this.getAttribute("data-copy");
    const copiedText = this.getAttribute("data-text-copied");
    const copyText = this.getAttribute("data-text");
    const timeout = this.getAttribute("data-timeout") || 1500;

    navigator.clipboard.writeText(text).then(() => {
      this.updateCopyText(copiedText);
      setTimeout(() => {
        this.updateCopyText(copyText);
      }, timeout);
    });
  }

  updateCopyText(textToUpdate) {
    const textElement = this.querySelector(".button__text");
    textElement.textContent = textToUpdate;
  }
}

customElements.define("copy-button", CopyButton);
