require("./_static/scss/index.scss");
require("./assets/camera.svg");
require("./assets/car-crashing.svg");
require("./assets/logo.svg");
require("./assets/maestro-s.svg");
require("./assets/mastercard-s.svg");
require("./assets/motorcycle-car.svg");
require("./assets/bag.svg");
require("./assets/box.svg");
require("./assets/no-coverage.svg");
require("./assets/placeholder.svg");
require("./assets/secure-payment.svg");
require("./assets/visa-s.svg");
require("./assets/favicon.png");
require("../src/Common/ComponentUI/CustomElements/copyButton");

import braintreePayment from "./_static/js/braintree-payment";
import createStylesFromfeatureFlags from "./_static/js/feature-flag";
import trackerSingleton from "./src/tracker";

let App = require("./../src/Main.elm");

let language_override = process.env.LANGUAGE_OVERRIDE;

var languages = navigator.languages;
if (language_override != null)
  languages = [language_override].concat(languages);

document.head.appendChild(
  createStylesFromfeatureFlags(process.env.FEATURE_FLAGS || "")
);

let app = App.Elm.Main.init({
  flags: {
    apiUrl: process.env.API_URL,
    externalApiUrl: process.env.EXTERNAL_API_URL,
    funnelUrl: process.env.FUNNEL_URL,
    loginPath: process.env.LOGIN_PATH,
    logoutPath: process.env.LOGOUT_PATH,
    localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    languages: languages,
  },
});

braintreePayment(app);

app.ports.requestIssueRefundReleaseDate.subscribe(() => {
  // Returns the issue refund release date in milliseconds
  app.ports.receiveManualRefundCutoff.send(
    Date.parse(process.env.MANUAL_REFUND_CUTOFF)
  );
  return;
});

app.ports.trackUserEvent.subscribe(trackerSingleton.trackCustomAction);
