import { datadogRum } from "@datadog/browser-rum";

/**
 * @typedef {Object} customAction
 * @property {string} type
 * @property {unknown} payload
 */

/**
 * @typedef {Object} TrackerProvider
 * @property {() => void} init
 * @property {(customAction) => void} trackCustomAction
 */

class Tracker {
  /**
   * Create a Tracker
   * @param {TrackerProvider} trackerProvider
   */
  constructor(trackerProvider) {
    this.trackerProvider = trackerProvider;
    this.init = this.init.bind(this);
    this.trackCustomAction = this.trackCustomAction.bind(this);
  }

  /**
   * Initialize Tracker instance
   */
  init() {
    this.trackerProvider.init();
    Object.freeze(this);
  }

  /**
   * Send Custom Action to remote tracker
   * @param {customAction} customAction
   */
  trackCustomAction(customAction) {
    this.trackerProvider.trackCustomAction(customAction);
  }
}

class DatadogSDKTracker {
  constructor() {}

  init() {
    datadogRum.init({
      applicationId: process.env.DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
      site: process.env.DATADOG_RUM_SITE,
      service: "es-fe-backoffice",
      env: process.env.DATADOG_RUM_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      version: process.env.VERSION,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask",
      allowedTracingUrls: [
        { match: process.env.API_URL, propagatorTypes: ["tracecontext"] },
        {
          match: process.env.EXTERNAL_API_URL,
          propagatorTypes: ["tracecontext"],
        },
      ],
    });
  }

  trackCustomAction(customAction) {
    datadogRum.addAction(customAction.type, customAction.payload);
  }
}

class MockTracker {
  init() {
    console.log("Init mock tracker");
  }

  trackCustomAction(customAction) {
    console.log("Sent Custom action!");
    console.table(customAction);
  }
}

/**
 *
 * @param {boolean} isDatadogEnabled
 * @returns {TrackerProvider}
 */
function trackerProviderStrategy(isDatadogEnabled) {
  let trackerProvider = new MockTracker();
  if (isDatadogEnabled) {
    trackerProvider = new DatadogSDKTracker();
  }
  return trackerProvider;
}

const trackerSingleton = new Tracker(
  trackerProviderStrategy(process.env.DATADOG_RUM_ENABLED === "true")
);

trackerSingleton.init();

export default trackerSingleton;
